import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const NotFoundPage: React.FC<{}> = () => (
  <Layout pageMeta={{ title: 'Evie - Page not found', description: "Sorry this page doesn't exist", keywords: '' }}>
    <div className="not-found">
      <h1>PAGE NOT FOUND</h1>
      <p>Oops, looks like this page doesn't exist</p>
      <Link className="btn green" to={'/'}>
        BACK TO THE HOME PAGE
      </Link>
    </div>
  </Layout>
);

export default NotFoundPage;
